<template>
    <div class="vipcenter">
        <div class="memberInfo">
            <div class="member-item">
                <div class="text1">{{ $t('dang-qian-deng-ji') }}</div>
                <div class="text2">{{ $t('pu-tong-hui-yuan') }}</div>
                <img src="../../assets/icon_guanjia.png" alt="" />
            </div>
            <div class="member-item">
                <div class="text1">{{ $t('hui-yuan-dao-qi-ri') }}</div>
                <div class="text2">{{$t('xian-shi-mian-fei')}}</div>
            </div>
        </div>
        <div class="title">{{ $t('hui-yuan-quan-yi-jie-shao') }}</div>
        <div class="fee">
            <FeeTable />
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import FeeTable from '../../components/vip/FeeTable.vue';
export default {
    name: 'vipCenter',
    data() {
        return {};
    },
    computed: {
        ...mapState(['vipInfo'])
        // member() {
        //     return this.vipInfo.member || {};
        // },
        // memberImg() {
        //     if (this.member.name === '高级会员') {
        //         return require('../../assets/icon_vip.png');
        //     } else if (this.member.name === '服务管家') {
        //         return require('../../assets/icon_guanjia.png');
        //     } else {
        //         return null;
        //     }
        // }
    },
    components: {
        FeeTable
    }
};
</script>
<style lang="scss" scoped>
.memberInfo {
    padding: 28px 90px;
}
.member-item {
    display: flex;
    padding: 12px 0;
    align-items: center;
    .text1 {
        font-size: 12px;
        color: #878d99;
        line-height: 17px;
        min-width: 70px;
        padding-right: 10px;
    }
    .text2 {
        font-size: 12px;
        font-weight: bold;
        color: #292c33;
    }

    img {
        // width: 48px;
        height: 24px;
        margin-left: 10px;
        display: block;
    }
}

.isEn {
    .member-item {
        .text1 {
            min-width: 120px;
        }
    }
}
.title {
    font-size: 12px;
    font-weight: bold;
    color: #292c33;
    line-height: 17px;
    padding: 30px;
    border-top: 20px solid #f5f7fa;
}
.fee {
    padding: 0 50px 50px 30px;
}
</style>
