<template>
    <div class="table-box">
        <div style="width:100%;max-width:900px">
            <el-table
                :data="feeData"
                :span-method="objectSpanMethod"
                :show-header="false"
                :row-class-name="tableRowClassName"
                :cell-class-name="tableCellClassName"
                border
            >
                <el-table-column prop="type" label="" align="center">
                    <template slot-scope="scope">
                        <span v-if="scope.row.isTop" class="table-header">{{ $t('gong-neng-quan-yi') }}</span>
                        <span v-else class="htitle">{{ getName(scope.row.type) }}</span>

                        <span class="tips" v-if="scope.row.subType">{{ scope.row.subType }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="name" label="" align="center">
                    <template slot-scope="scope">
                        <span v-if="scope.row.isTop"></span>
                        <span v-else class="title">{{ getName(scope.row.name) }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="exhibitors" label="普通会员" align="center">
                    <template slot-scope="scope">
                        <div v-if="scope.row.isTop" class="table-header">
                            <!-- <div class="header-top">普通会员</div>
                            <div class="text2">免费</div> -->
                            {{ $t('shuo-ming') }}
                        </div>
                        <span v-else-if="scope.row.exhibitors === false">--</span>
                        <i v-else-if="scope.row.exhibitors === true" class="el-icon-success" type="success"></i>
                        <span v-else>{{ getName(scope.row.exhibitors) }}</span>
                    </template>
                </el-table-column>
                <!-- <el-table-column prop="vip" label="高级会员" align="center">
                    <template slot-scope="scope">
                        <div v-if="scope.row.isTop" class="table-header">
                            <div class="header-top">
                                <span>高级会员</span>
                                <img
                                    src="../../assets/ManufacturersEntering/icon_vip.png"
                                    alt=""
                                />
                            </div>
                            <div class="text2">1800 <small>元/年</small></div>
                        </div>
                        <span v-else-if="scope.row.vip === false">--</span>
                        <i
                            v-else-if="scope.row.vip === true"
                            class="el-icon-success"
                            type="success"
                        ></i>
                        <span v-else>{{ scope.row.vip }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="vvip" label="服务管家" align="center">
                    <template slot-scope="scope">
                        <div v-if="scope.row.isTop" class="table-header">
                            <div class="header-top">
                                <span>服务管家</span>
                                <img
                                    src="../../assets/ManufacturersEntering/icon_guanjia.png"
                                    alt=""
                                />
                            </div>

                            <div class="text2">3800 <small>元/年</small></div>
                        </div>
                        <span v-else-if="scope.row.vvip === false">--</span>
                        <i
                            v-else-if="scope.row.vvip === true"
                            class="el-icon-success"
                            type="success"
                        ></i>
                        <span v-else>{{ scope.row.vvip }}</span>
                    </template>
                </el-table-column> -->
            </el-table>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex';
export default {
    name: 'fee',
    data() {
        return {
            feeData: [
                {
                    type: {
                        chName: '功能/权益',
                        enName: 'Function/Right and Interest'
                    },
                    colspan: 2,
                    isTop: true
                },
                {
                    type: {
                        chName: '展厅装修',
                        enName: 'Online Showroom Management (HomePage)'
                    },
                    name: { chName: '展厅主页装修', enName: 'Decoration for company HomePage' },
                    exhibitors: true,
                    vip: true,
                    vvip: true,
                    rowspan: 6
                },
                {
                    name: { chName: '产品上传/维护', enName: 'Product upload and maintenance' },
                    exhibitors: true,
                    vip: true,
                    vvip: true
                },
                {
                    name: { chName: '自定义展位排版', enName: 'Customized layout for company HomePage' },
                    exhibitors: true,
                    vip: true,
                    vvip: true
                },
                {
                    name: { chName: '自定义导航条颜色', enName: 'Customized navigational bar for Online Showroom' },
                    exhibitors: true,
                    vip: true,
                    vvip: true
                },
                {
                    name: { chName: '在轮播图中添加视频', enName: 'Video upload for Banner' },
                    exhibitors: true,
                    vip: true,
                    vvip: true
                },
                {
                    name: { chName: '顶部轮播图数量', enName: 'Number of Banner' },
                    exhibitors: { chName: '5张', enName: '5' },
                    vip: '不限',
                    vvip: '不限'
                },
                {
                    type: { chName: '后台功能', enName: 'Management Center' },
                    name: { chName: '信息更新维护', enName: 'Online Showroom content maintenance' },
                    exhibitors: true,
                    vip: true,
                    vvip: true,
                    rowspan: 6
                },
                {
                    name: { chName: '产品维护', enName: 'Product maintenance' },
                    exhibitors: true,
                    vip: true,
                    vvip: true
                },
                {
                    name: { chName: '平台代理维护', enName: 'Content maintenance service by T-PARK ' },
                    exhibitors: { chName: '咨询客服', enName: 'Contact for detail' },
                    vip: false,
                    vvip:
                        'T-PARK为会员进行网站内容的上传、编辑与维护，会员按照网站内容结构负责提供所涉及内容的文档、资料、图片等。'
                },
                {
                    type: '后台功能',
                    name: { chName: '产品/企业信息翻译', enName: 'Content translation' },
                    exhibitors: { chName: '咨询客服', enName: 'Contact for detail' },
                    vip: false,
                    vvip: 'T-PARK为会员提供网站信息内容的翻译服务，保证网站中文版本与英文版本内容的对应与完整'
                },
                {
                    type: '后台功能',
                    name: { chName: '客服问询信息查询/回复', enName: 'Customers service' },
                    exhibitors: true,
                    vip: true,
                    vvip:
                        'T-PARK协助会员进行网站客户服务，对到访用户的问询信息进行查询、反馈与维护管理等，保障对到访用户的问询信息进行及时的反馈与处理'
                },
                {
                    type: '后台功能',
                    name: { chName: '客服协助服务', enName: 'Customers service assistant by T-PARK' },
                    exhibitors: { chName: '咨询客服', enName: 'Contact for detail' },
                    vip: true,
                    vvip:
                        'T-PARK协助会员进行网站客户服务，对到访用户的问询信息进行查询、反馈与维护管理等，保障对到访用户的问询信息进行及时的反馈与处理'
                },
                {
                    type: { chName: '推广展示', enName: 'Promotion Display' },
                    // subType: '每个推广位投放不少于7天最多 15天',
                    name: { chName: '平台主页广告推广', enName: 'Home page promotion' },
                    exhibitors: { chName: '咨询客服', enName: 'Contact for detail' },
                    vip: '20条/年',
                    vvip: '20条/年',
                    rowspan: 5
                },
                {
                    name: { chName: '新闻活动简报广告', enName: 'Promotion area of News' },
                    exhibitors: { chName: '咨询客服', enName: 'Contact for detail' },
                    vip: '15条/年',
                    vvip: '15条/年'
                },
                {
                    name: { chName: 'BANNER位推广', enName: 'BANNER' },
                    exhibitors: { chName: '咨询客服', enName: 'Contact for detail' },
                    vip: '20条/年',
                    vvip: '20条/年'
                },
                {
                    name: { chName: '相关产品位', enName: 'Related products' },
                    exhibitors: { chName: '咨询客服', enName: 'Contact for detail' },
                    vip: '15条/年',
                    vvip: '15条/年'
                },
                {
                    name: { chName: '产品展示竞价排名', enName: 'Ranking optimization' },
                    exhibitors: { chName: '咨询客服', enName: 'Contact for detail' },
                    vip: '企业及产品排序优化',
                    vvip: '企业及产品排序优化'
                },
                // {
                //     type: '数据服务',
                //     name: '采购商信息查询',
                //     exhibitors: false,
                //     vip: '免费查询',
                //     vvip: '免费查询',
                //     rowspan: 3
                // },
                {
                    type: { chName: '数据服务', enName: 'Information and Data' },
                    name: { chName: '店铺访问/用户行为数据', enName: 'Visiting Data' },
                    exhibitors: true,
                    vip: '免费查询',
                    vvip: '免费查询',
                    rowspan: 2
                },
                {
                    name: { chName: '相关数据统计报告', enName: 'Report' },
                    exhibitors: { chName: '咨询客服', enName: 'Contact for detail' },
                    vip: true,
                    vvip: true
                }
            ]
        };
    },
    computed: {
        ...mapState(['userInfo'])
    },
    methods: {
        objectSpanMethod({ row, column, rowIndex, columnIndex }) {
            if (columnIndex === 0) {
                if (rowIndex == 0) {
                    return {
                        rowspan: 1,
                        colspan: 2
                    };
                } else if (row['rowspan']) {
                    return {
                        rowspan: row['rowspan'],
                        colspan: 1
                    };
                } else {
                    return {
                        rowspan: 0,
                        colspan: 0
                    };
                }
            } else if (columnIndex == 1 && rowIndex == 0) {
                return {
                    rowspan: 0,
                    colspan: 0
                };
            } else {
                return {
                    rowspan: 1,
                    colspan: 1
                };
            }
        },
        tableRowClassName({ row, rowIndex }) {
            if (rowIndex === 0) {
                return 'top-row';
            } else if (rowIndex % 2 == 0) {
                return 'info-row';
            }
            return '';
        },
        tableCellClassName({ row, column, rowIndex, columnIndex }) {
            if (columnIndex === 0) {
                if (row['rowspan']) {
                    return 'main-cell';
                }
            }
            return '';
        }
    }
};
</script>
<style lang="scss" scoped>
.table-header {
    font-size: 18px;
    // font-weight: bold;
    color: #ffffff;
    line-height: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .header-top {
        display: flex;
        align-items: center;
        font-size: 16px;
        color: #ffffff;
        line-height: 22px;
        span {
            color: #ffffff;
            font-size: 16px;
            line-height: 22px;
        }

        img {
            width: auto;
            height: 24px;
            display: block;
        }
    }

    .text2 {
        color: #ffa526;
        margin-top: 10px;

        small {
            font-size: 14px;
        }
    }
}

.table-box {
    display: flex;
    flex-direction: column;
    align-items: center;
}
</style>

<style lang="scss">
@import '../../styles/variables';

.el-table__row {
    &.top-row {
        background-color: #0f264d;
        height: 70px;

        td {
            border-color: rgba($--table-border-color, 10%);
        }
    }

    &.info-row {
        background-color: #f7f8fa;
    }

    .main-cell {
        background-color: #f0f2f5;
    }

    .el-icon-success {
        color: #1dd2ac;
        font-size: 18px;
    }

    span {
        font-size: 12px;
        color: #292c33;
        line-height: 17px;

        &.title {
            font-size: 14px;
            color: #292c33;
            line-height: 20px;
        }

        &.htitle {
            font-size: 14px;
            font-weight: bold;
            color: #292c33;
            line-height: 20px;
        }

        &.tips {
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #878d99;
            line-height: 17px;
            width: 82px;
            position: absolute;
            top: 50%;
            left: 42px;
            transform: translateY(14px);
            text-align: left;
        }
    }

    .cell {
        padding-left: 20px;
        padding-right: 20px;
    }
    td:first-child .cell {
        padding-left: 20px;
    }
}
</style>
